/* @import 'reset';
@import 'mixins';
@import 'variabels';
@import 'typography';
@import 'layout';

@import 'main';
*/

@import 'reset';
@import 'mixins';
@import 'variabels';
@import 'typography';

// Components
@import 'blue-screen';
@import 'articles';
@import 'header';
@import 'main';
@import 'footer';


html {
    background-color: var(--color-background);
}

header,
main,
footer,
.bye {
    margin: var(--grid-margin);

    @include splitScreen {
        display: grid;

        gap: var(--grid-gap);
    }
    > * {
        margin-bottom: var(--grid-margin);

        @include splitScreen {
            margin-bottom: 0;
        }
    }
    //  display: grid;
}
